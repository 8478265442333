import { Injectable } from '@angular/core';
import { CurrentUserPreferenceStore } from '../../core/store/current-user-preference.store';
import { TableConversion } from './table.conversion';
import { UserConversion } from '../../features/organization/users/conversion/user.conversion';
import { MainConversionInterface } from '../../core/interfaces/conversion/main-conversion.interface';
import {
  ActionPlanDetailsDTO,
  ActionPlanFormDTO,
  ActionPlanListDTO,
} from '../dto/action-plan.dto';
import { FormGroup } from '@angular/forms';
import { convertResponseToSeverityLevelDetailsDTO } from '../../features/settings/masters/dto/severity-level.dto';
import { convertResponseToUserMinimalDTO } from '../../features/organization/users/dto/user.dto';
import { convertResponseToActionPlanStatuDetailsDTO } from '../../features/settings/masters/dto/action-plan-status.dto';
import * as am5 from '@amcharts/amcharts5';
import { pieChartData } from '../../core/modals/chart.modal';

@Injectable({
  providedIn: 'root',
})
export class ActionPlanConversion
  implements
    MainConversionInterface<
      ActionPlanListDTO,
      ActionPlanFormDTO,
      ActionPlanDetailsDTO
    >
{
  private lang: string;

  constructor(
    private currentUserPreferenceStore: CurrentUserPreferenceStore,
    private tableConv: TableConversion,
    private userConv: UserConversion
  ) {
    this.lang = this.currentUserPreferenceStore.languageKey;
  }

  resToList(response: any[]): ActionPlanListDTO[] {
    if (!response) {
      return [];
    }
    return response.map((item) => ({
      id: item.id,
      title: item.title,
      description: item.description,
      severity_level: this.tableConv.resToColordItem(item, 'severity_level'),
      responsible_user: this.userConv.resToUserMinimalDTO(
        item,
        'responsible_user'
      ),
      target_date: item.target_date,
      percentage: item.percentage,
      comments: '',
      action_plan_status: this.tableConv.resToColordItem(
        item,
        'action_plan_status'
      ),
      created_at: item.created_at,
      updated_at: item.updated_at,
      created_user: this.userConv.resToUserMinimalDTO(item, 'created_user'),
      updated_user: this.userConv.resToUserMinimalDTO(item, 'updated_user'),
    }));
  }

  formGroupToForm(formGroup: FormGroup): ActionPlanFormDTO {
    return {
      id: formGroup.get('id')?.value,
      title: formGroup.get('Title')?.value,
      description: formGroup.get('Description')?.value,
      severity_level_id: parseInt(formGroup.get('SeverityLevel')?.value, 10),
      responsible_user_id: formGroup.get('ResponsibleUser')?.value,
      target_date: formGroup.get('TargetDate')?.value,
      action_plan_status_id: formGroup.get('ActionPlanStatus')?.value,
      percentage: parseInt(formGroup.get('Percentage')?.value),
    };
  }
  resToForm(response: any) {
    return {
      id: response.id,
      Title: response.title,
      Description: response.description,
      ComplianceRequirement: response.compliance_requirement?.id,
      SeverityLevel: response.severity_level?.id,
      ResponsibleUser: response.responsible_user?.id,
      TargetDate: response.target_date,
      ActionPlanStatus: response.action_plan_status?.id,
      Percentage: response.percentage,
    };
  }
  resToDetails(response: any): ActionPlanDetailsDTO {
    return {
      id: response.id,
      title: response.title,
      description: response.description,
      severity_level: response.severity_level
        ? convertResponseToSeverityLevelDetailsDTO(
            response.severity_level,
            this.lang
          )
        : null,
      responsible_user: response.responsible_user
        ? convertResponseToUserMinimalDTO(response.responsible_user, this.lang)
        : null,
      action_plan_status: convertResponseToActionPlanStatuDetailsDTO(
        response.action_plan_status,
        this.lang
      ),
      target_date: response.target_date,
      percentage: response.percentage,
      created_at: response.created_at,
      updated_at: response.updated_at ? response.updated_at : null,
      created_by: convertResponseToUserMinimalDTO(
        response.created_by_user,
        this.lang
      ),
      updated_by: response.updated_by_user
        ? convertResponseToUserMinimalDTO(response.updated_by_user, this.lang)
        : null,
    };
  }

  resOpenClosedPieChart(response: any): pieChartData[] {
    const data: pieChartData[] = [];

    if (response.open_count) {
      data.push({
        category: this.lang === 'en' ? 'Open' : 'مفتوح',
        count: response.open_count,
        sliceSettings: {
          fill: am5.color(0xe0554d), // Dark Red color
        },
      });
    }

    if (response.closed_count) {
      data.push({
        category: this.lang === 'en' ? 'Closed' : 'مغلق',
        count: response.closed_count,
        sliceSettings: {
          fill: am5.color(0x28a745),
        },
      });
    }

    return data;
  }
}
